.alert {
    padding: $alert-padding-y $alert-padding-x;
    border: 0;
    font-size: $font-size-sm;
    @include border-radius($alert-border-radius);

    .alert-inner--icon {
        font-size: 1.25rem;
        margin-right: 1.25rem;
        display: inline-block; 
        vertical-align: middle;

        i.ni {
            position: relative;
            top: 1px;
        }
    }
    .alert-inner--text {
        display: inline-block;
        vertical-align: middle;
    }
}

.alert:not(.alert-secondary) {
    color: $white;
}

[class*="alert-"] {
    .alert-link {
        color: $white; 
        border-bottom: 1px dotted rgba($white, .5);
    }
}

.alert-heading {
    font-weight: $font-weight-bold;
    font-size: $h4-font-size;
    margin-top: .15rem; 
} 

.alert-dismissible {
    .close { 
        top: 50%;
        right: $alert-padding-x;
        padding: 0;
        transform: translateY(-50%);
        color: rgba($white, .6);
        opacity: 1;

        &:hover,
        &:focus {
            color: rgba($white, .9);
            opacity: 1 !important;
        }

        @include media-breakpoint-down(xs) {
            top: 1rem;
            right: .5rem;
        }

        &>span:not(.sr-only) {
            font-size: 1.5rem;
            background-color: transparent;
            color: rgba($white, .6);
        }

        &:hover,
        &:focus {
            &>span:not(.sr-only) {
                background-color: transparent;
                color: rgba($white, .9);
            }
        }
    }
}
